import './App.css';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { parse } from 'qs';
import termsOfServices from './md/terms_of_services.md';
import privacyNotice from './md/privacy_notice.md';
import { useState, useEffect } from 'react';

function App() {
    const [content, setContent] = useState('');

    //Use componentDidMount(): if class based component to load md file
    useEffect(() => {
        const params = parse(window.location.href.split('?')[1]);
        const { file } = params;
        let markdownName = '';
        if (file === 'termsOfServices') {
            markdownName = termsOfServices;
            document.title = 'Terms Of Services';
        } else if (file === 'privacyNotice') {
            markdownName = privacyNotice;
            document.title = 'Privacy Notice';
        }

        if (markdownName) {
            fetch(markdownName)
                .then(data => data.text())
                .then(text => {
                    setContent(text);
                });
        }
    }, []);

    return (
        <div className="App">
            <ReactMarkdown children={content}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]} />
        </div>
    );
}

export default App;
